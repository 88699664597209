.alert-enter {
    opacity: 0;
    transform: translateY(100%) rotateX(90deg);
    }
    .alert-enter-active {
    opacity: 1;
    transform: translateY(0) rotateX(0);
    transition: opacity 1000ms, transform 1000ms;
    }
    .alert-exit {
    opacity: 1;
    }
    .alert-exit-active {
    opacity: 0;
    transform: translateY(100%) rotateX(90deg);
    transition: opacity 1000ms, transform 1000ms;
    }


    