.navbar {
    position: sticky !important;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px ;
    background-color: transparent ;
    background-image: linear-gradient(to bottom,#f3911f 0%,#F3911F00 100%);
    opacity: 1;
    transition: background 0.3s,border-radius 0.3s,opacity 1s;
    /*background: linear-gradient(to bottom, #f5bb7e, #ffffff);*/
    z-index: 999;
  }

  .navbarAdmin {
    position: sticky !important;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0px ;
    background-color: #F27216;
    opacity: 1;
    transition: background 0.3s,border-radius 0.3s,opacity 1s;
    /*background: linear-gradient(to bottom, #f5bb7e, #ffffff);*/
    z-index: 999;
  }
  
  .videowrapper{
    margin-top: 10vh;
    height: auto;
    width: auto;
    border: 5px solid gray;
    border-radius: 10px;
    overflow: hidden;
  }
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links li a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
  }


/*Cards*/

.front:after {
    content: ' INVISIBLE ';
    position: absolute;
    width: 40%;
    height: 10px;
    left: 70px;
    bottom: 12px;
    z-index: -1;
    -webkit-transform: translateZ(-1px);
    -webkit-backface-visibility: hidden;
  }
  
  .back:after {
    content: ' INVISIBLE ';
    position: absolute;
    width: 40%;
    height: 10px;
    left: 70px;
    bottom: 12px;
    z-index: -1;
    -webkit-transform: translateZ(-1px);
    -webkit-backface-visibility: hidden;
  }
  
  .flip-container {
    -webkit-perspective: 1000;
  }
      
  /* flip the pane when hovered */
  .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
  }
  
  .flip-container, .front, .back {
      width: 100px;
      height: 100px;
  }
  
  /* flip speed goes here */
  .flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
     position: relative;
      left: 30px;
  }
  
  /* hide back of pane during swap */
  .front, .back {
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  .back h1 {
    -webkit-backface-visibility: hidden;
  
  }
  
  /* back, initially hidden pane */
  .back {
    -webkit-transform: rotateY(180deg) translateZ(1px);
        -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  /* Define styles for the entering state */
.alert-enter {
  opacity: 0;
}

/* Define styles for the entered state */
.alert-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

/* Define styles for the leaving state */
.alert-exit {
  opacity: 1;
}

/* Define styles for the exited state */
.alert-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
.boxholder {
  position: relative;

}
.boxholder .front, .boxholder .back {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #C3C3C3;
  color: #3b3b3b;
  transition: 1s;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.boxholder .back {
  transform: rotateY(180deg);
}
.boxholder.showinfo .back {
  transform: rotateY(0deg);
}
.boxholder.showinfo .front {
  transform: rotateY(-180deg);
}