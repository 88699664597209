
body {
    margin: 0;
    height: 100%;
    padding:1000;
  }


  .greencircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #00db49;
  }
  .redcircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #db0000;
  }
  
  .bigblue1 {
    color: DodgerBlue;
    padding: 100px;
    font-family: Arial;
    text-align: center;
    background-color: White;
  } 
  .parallaxcontent {
    position: sticky;
    top: 0;
    z-index: 100;
    /* Additional styles for the Navbar */
    background-color: #ffffff; /* Set the desired background color */
    padding: 10px; /* Adjust the padding as needed */
  }
  .parallaxwrapper {
    position: relative;
    overflow: hidden;
  }
  
  .parallaxbg1,
  .parallaxbg2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .parallaxbg1 {
    background-image: url('../images/bg1.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
  
  .parallaxbg2 {
    background-image: url('../images/bg2.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }
  
  .parallax-content {
    position: relative;
    z-index: 1;
  }

.container {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }

  .hero {
    position: relative;
    height: auto;
    background-repeat: repeat;

  }

  
  .content {
    text-align: center;
    z-index: 1;
    color: #fff;
  }
  
.imageContainer {
  width: 400px; /* Set the width of the container */
  height: 400px; /* Set the height of the container */
  background-size: cover; /* Scale the background image to cover the container */
  background-position: center; /* Center the background image */
  transition: all 0.3s ease-in-out; /* Add a smooth transition effect */
  border-radius: 500px;
  margin:100px;
}

.imageContainerNoMargin {
  width: 600px; /* Set the width of the container */
  height: 400px; /* Set the height of the container */
  background-size: cover; /* Scale the background image to cover the container */
  background-position: center; /* Center the background image */
  transition: all 0.3s ease-in-out; /* Add a smooth transition effect */
}

.imageContainerNoMargin:hover{
  transform: scale(1.2); /* Increase the size of the container by 20% on hover */
  border-radius: 50px;
}

.imageContainer:hover {
  transform: scale(1.2); /* Increase the size of the container by 20% on hover */
  border-radius: 50px;
}