/* table {
  width: 500px;
  border-width: 0px;
  border-color: black;
  border-style: solid;
  border-radius: 10px;
}

table tbody tr td {
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  border-color: black;
}
table tbody tr td:last-child {
  border-right: 0px;
} */

tr thead {
  height: 100px;
}